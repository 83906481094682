import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { navigate } from 'gatsby';

const Details = ({ list, solutionLevelThreeSlug }) => {
  return (
    <div className="flex flex-col">
      {list?.map((solutionLevelThree) => (
        <Fragment key={solutionLevelThree?.id}>
          <details
            open={
              solutionLevelThreeSlug === solutionLevelThree?.attributes?.slug
            }
          >
            <summary
              className="mb-5 w-full relative inline-block"
              onClick={() => {
                if (
                  solutionLevelThreeSlug ===
                  solutionLevelThree?.attributes?.slug
                ) {
                  navigate('');
                  return;
                }
                navigate('?type=' + solutionLevelThree?.attributes?.slug);
              }}
            >
              <h2 className="font-bold text-2xl-xxl text-xl text-blue-600">
                {solutionLevelThree?.attributes?.name}
              </h2>
            </summary>
            {solutionLevelThree?.attributes?.description && (
              <div className="font-light text-lg-xxl text-base text-gray-700 w-90">
                <BlocksRenderer
                  content={solutionLevelThree?.attributes?.description}
                />
              </div>
            )}
            <div className="grid grid-cols-3 gap-6">
              {solutionLevelThree?.attributes?.products?.data?.map(
                (product) => (
                  <div
                    key={product?.attributes?.name}
                    className={`p-6 flex flex-col border border-gray-200 mt-6 ${product?.attributes?.description?.length ? 'grid-span-3' : 'grid-span-1-lg grid-span-3'}`}
                  >
                    <h3 className="text-xl-xxl text-lg font-bold text-blue-800 mb-4">
                      {product?.attributes?.name}
                    </h3>
                    {product?.attributes?.description && (
                      <div className="font-light text-base-xxl text-sm text-gray-700 mb-4">
                        <BlocksRenderer
                          content={product?.attributes?.description}
                        />
                      </div>
                    )}
                    <div className="flex items-center gap-3 flex-wrap">
                      <button
                        className={`flex gap-3 items-center justify-center bg-blue-400 text-white py-5-xxl py-4 px-5-xxl px-4 text-base-xl text-sm font-medium border border-blue-400 ${!product.description ? 'w-full' : ''}`}
                      >
                        Télécharger les documents
                        <StaticImage
                          src="../../../images/icons/download.svg"
                          quality={100}
                          width={18}
                          height={18}
                          alt="download image"
                        />
                      </button>
                      <button
                        className={`bg-transparent gap-3 border border-blue-400 text-blue-800  py-5-xxl py-4 px-8-xxl px-6 text-base-xl text-sm font-medium ${!product.description ? 'w-full' : ''}`}
                      >
                        Demander un devis
                      </button>
                    </div>
                  </div>
                ),
              )}
            </div>
          </details>
          <hr className="h-1-px w-full bg-blue-800 my-8" />
        </Fragment>
      ))}
    </div>
  );
};

Details.propTypes = {
  list: PropTypes.array,
  solutionLevelThreeSlug: PropTypes.string,
};

export default Details;
