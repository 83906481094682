import React from 'react';
import PropTypes from 'prop-types';
import Tabs from './Tabs';
import Details from './Details';

const SolutionLevelTwos = ({
  solutionLevelTwo,
  solutionLevelThreeSlug,
  solutionLevelTwos,
  currentSolutionSlug,
}) => {
  return (
    <div className="py-20-xxl py-12 max-w-1200 container px-6 w-90-lg w-full mx-auto">
      <span className="inline-block text-blue-900 mb-6 font-bold text-3xl-xxl text-2xl ">
        Nos products
      </span>
      <Tabs
        currentSolutionSlug={currentSolutionSlug}
        tabs={solutionLevelTwos}
        currentTab={solutionLevelTwo?.attributes?.slug}
      />
      <Details
        list={solutionLevelTwo?.attributes['solution_level_threes']?.data}
        solutionLevelThreeSlug={solutionLevelThreeSlug}
      />
    </div>
  );
};

SolutionLevelTwos.propTypes = {
  solutionLevelTwo: PropTypes.object,
  solutionLevelTwos: PropTypes.array,
  solutionLevelThreeSlug: PropTypes.string,
  currentSolutionSlug: PropTypes.string,
};

export default SolutionLevelTwos;
